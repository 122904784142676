import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { getStoreInfoFromFeed } from '@helpers/store-locator'
import { scrollTo } from '@helpers/bodyscroll'
import { Grid, Stack, Link, styled, css, Typography, Chip, Divider, Box, useTheme, useMediaQuery } from '@mui/material'
import RadarMapStore, { RadarFlipIcon } from '@components/radar-map-store/RadarMapStore'
import usePageAnalytics from '@hooks/usePageAnalytics'
import { useStore, FETCH_PROPERTIES, useGetStoreInfo } from '@hooks/useStore'
import Layout from '@components/layout'
import StoreV1 from '@components/store-details-v1/index'
import StoreDescription from '@components/store-details-v2/store-description'
import StoreHours from '@components/store-details-v2/store-hours'
import StoreSeo from '@components/store-details-v2/store-seo'
import InStoreAppointment from '@components/store-details-v2/in-store-appointment'
import StoreNeighborhoods from '@components/store-details-v2/store-neighborhoods'
import StoreDirections from '@components/store-details-v2/store-directions'
import StoreSwiper from '@components/store-details-v2/store-swiper'
import StoreAddress from '@components/store-details-v2/store-address'
import StorePhone from '@components/store-details-v2/store-phone'
import StoreHoursToday from '@components/store-details-v2/store-hours-today'
import StoreFeatures from '@components/store-details-v2/store-features'
import StoreAvailableHere from '@components/store-details-v2/store-available-here'
import StoreQuestions from '@components/store-details-v2/store-questions'
import SpecialClosings from '@components/store-details-v2/special-closings'
import mapThumbnailImage from '@assets/images/map-thumbnail.png'
import { formatReviews } from '@helpers/reviews'
import StoreReviews from '../../@rtg2022/components/organisms/Reviews/reviews'
import StoreReviewsShort from '../../@rtg2022/components/organisms/Reviews/reviews-short'

import Header from '../../@rtg2022/components/organisms/Stores/Header/Header'
import Accordion from './content-types/Accordion'
import Banner from './content-types/Banner'

import '@assets/css/pages/store-location.sass'

const StyledHeader = styled(Header)(() => ({
  marginTop: 15,
}))

const StyledGrid = styled(Grid)(
  ({ theme }) => css`
    margin-bottom: 3rem;
    ${theme.breakpoints.down('md')} {
      margin-bottom: 0;
    }
  `,
)

const StoreStyles = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.background.default};
    margin: 0 auto;
    padding: 0 1rem;
    position: relative;

    hr {
      border-color: rgba(0, 83, 160, 0.2);
    }
  `,
)

const StyledAccordionsWrapper = styled('div')(
  ({ theme }) => css`
    margin-top: 4rem;
    .MuiAccordionDetails-root p {
      margin: 0;
    }
    .MuiAccordion-root.Mui-expanded {
      margin: 0;
    }
    .MuiAccordionSummary-content h2 {
      text-transform: none;
      font-weight: 400;
    }
    .MuiButtonBase-root.MuiAccordionSummary-root {
      border: none;
      margin-bottom: 0;
    }
    .MuiPaper-root.MuiAccordion-root {
      border-bottom: 0.0625rem solid #dedede;
    }
  `,
)

const StyledSectionTitle = styled(Typography)(
  ({ theme }) => css`
    font-size: 20px;
    font-weight: 600;
    text-transform: none;
    color: ${theme.palette.primary.dark};

    ${theme.breakpoints.up('md')} {
      font-size: 25px;
    }
  `,
)

const BannerWrapperStyled = styled('div')(
  ({ theme }) => css`
    position: absolute;
    top: 30px;
    width: 80%;
    left: 10%;
    z-index: 1;

    .MuiGrid-item {
      margin-left: auto;
      margin-right: auto;
      margin: auto;
    }

    ${theme.breakpoints.down('md')} {
      position: initial;
      margin-left: 0;
      width: 100%;

      .MuiGrid-item .strapi-markdown {
        padding: 0;
      }
    }
  `,
)

const StyledStoreReviewsShort = styled(StoreReviewsShort)({
  paddingTop: 15,
})

const Store = ({ data }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const store = data.strapiStore
  const {
    storeNumber,
    storeExtendedStoreType,
    storeExtendedName,
    storeExtendedLocationAndName,
    availableInStore,
    reviews,
  } = useStore(
    {
      store,
    },
    [FETCH_PROPERTIES.STORE_REVIEWS],
  )

  const storeInfo = useGetStoreInfo(storeNumber)
  const storeInfoFromFeed = getStoreInfoFromFeed(storeNumber)
  const formattedReviews = useMemo(() => formatReviews({ reviewsData: reviews }), [reviews])

  usePageAnalytics({
    type: 'store',
    title: storeExtendedName,
  })

  const handleReviewsClick = () => {
    const reviewsAccordion = document.getElementById('customer-store-reviews-header')
    if (!reviewsAccordion?.classList?.contains?.('Mui-expanded')) {
      reviewsAccordion?.click?.()
    }
    scrollTo('store-neighborhoods-header')
  }

  const renderBanner = banner => {
    if (banner) {
      return <Banner data={banner} isMobile={isMobile} />
    }
    return null
  }

  const switchItems = useMemo(
    () => [
      {
        key: store?.StoreImage?.hash,
        Component: () => (
          <Box
            sx={{
              backgroundImage:
                isMobile && store?.StoreImage?.url
                  ? `url(${store?.StoreImageMobile?.url || store?.StoreImage?.url})`
                  : `url(${store?.StoreImage?.url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          />
        ),
      },
      {
        key: 'map',
        Component: props => <RadarMapStore variant="radar-map-store-v2" store={store} {...props} />,
        CustomFlipComponent: () => (
          <img src={mapThumbnailImage} alt="map-thumbnail" style={{ width: '100%', objectFit: 'cover' }} />
          // <RadarFlipIcon />
          // <RadarMapStore variant="radar-map-store-flip-v2" store={store} hideDirections hideNav />
        ),
      },
    ],
    [isMobile, store],
  )

  if (!store) return null

  if (store.NewTemplateVersion) {
    return (
      <StoreStyles>
        <StoreSeo seo={store.SEO.SEO} storeName={store.StoreName} storeTypeTitle={storeExtendedStoreType} />
        <Helmet title={store?.SEO?.SEO?.PageTitle} />
        <Grid container>
          {store.SpecialClosingsBanner?.Banner && (
            <BannerWrapperStyled>
              <Banner data={store.SpecialClosingsBanner} />
            </BannerWrapperStyled>
          )}
          <StyledGrid item xs={12}>
            <StyledHeader title={storeExtendedLocationAndName} switchItems={switchItems} />
            {store.SpecialClosings?.Markdown && <SpecialClosings specialClosings={store.SpecialClosings} />}
          </StyledGrid>
          <Grid item xs={12} md={3}>
            <Link
              href="/stores"
              sx={{
                margin: '1.5rem 0',
                textDecoration: 'none',
                display: { xs: 'inline-block', md: 'none' },
              }}
            >
              &lt; Back to Store Locations
            </Link>
            <Typography
              variant="h2"
              fontSize="19px"
              fontWeight="600"
              textTransform="none"
              color="text.primary"
              marginBottom="1rem"
              sx={{
                display: { xs: '  block', md: 'none' },
              }}
            >
              {storeExtendedLocationAndName}
            </Typography>
            <Stack spacing={2}>
              <StoreAddress storeInfoFromFeed={storeInfoFromFeed} store={store} />
              <StorePhone store={store} storeInfoFromFeed={storeInfoFromFeed} />
              <StyledStoreReviewsShort
                reviewsData={formattedReviews}
                onReviewsClick={handleReviewsClick}
                overallScore={storeInfo?.storeOverallScore}
              />
            </Stack>
            <hr />
            <StoreHoursToday storeInfoFromFeed={storeInfoFromFeed} />
            <StoreHours storeInfoFromFeed={storeInfoFromFeed} />
            <hr />
            <InStoreAppointment store={store} storeInfo={storeInfo} />
            <StoreQuestions />
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ width: 'auto', marginLeft: '3rem', marginRight: '2rem' }} />
          <Grid item xs={12} md={6} lg={5}>
            <Link
              href="/stores"
              sx={{ marginBottom: '40px', display: { xs: 'none', md: 'inline-block' }, textDecoration: 'none' }}
            >
              &lt; Back to Store Locations
            </Link>
            <Typography variant="body1" fontSize="19px" fontWeight="600" textTransform="none" color="primary.dark">
              Available Here:
            </Typography>
            <StoreAvailableHere availableItems={availableInStore} />
            <StoreDescription description={store.Description} />
          </Grid>
          <Grid item md={3} lg={4} sx={{ display: { xs: 'none', md: 'block' } }}>
            &nbsp;
          </Grid>
          <Grid item xs={12}>
            <StoreSwiper slides={store.ImageGallery} />
          </Grid>
          <Grid item xs={12}>
            <StyledSectionTitle variant="h2">Store Features</StyledSectionTitle>
            <StoreFeatures />
          </Grid>
          <Grid item xs={12}>
            {renderBanner(store.Banner, false)}
          </Grid>
          <Grid item xs={12}>
            <StyledAccordionsWrapper>
              <StoreNeighborhoods neighborhoodsNearby={store.NearbyNeighborhoods} />
              <StoreDirections directions={store.Directions} />
              {store.Accordion?.map(accordion => (
                <>
                  <Accordion data={accordion.Accordion} />
                </>
              ))}
              {reviews?.length > 0 && (
                <StoreReviews reviewsData={formattedReviews} overallScore={storeInfo?.storeOverallScore} />
              )}
            </StyledAccordionsWrapper>
          </Grid>
        </Grid>
      </StoreStyles>
    )
  }
  return <StoreV1 data={store} reviews={reviews} storeInfo={storeInfo} />
}

const StoreWrapper = ({ data }) => (
  <Layout>
    <Store data={data} />
  </Layout>
)

const storeShape = PropTypes.shape({
  strapiStore: PropTypes.shape({
    Accordion: PropTypes.array,
    Banner: PropTypes.object,
    City: PropTypes.string,
    Description: PropTypes.object,
    Directions: PropTypes.object,
    FAQs: PropTypes.object,
    ImageGallery: PropTypes.array,
    NearbyNeighborhoods: PropTypes.array,
    NewTemplateVersion: PropTypes.bool,
    HomeFurnitureAvailable: PropTypes.bool,
    KidsFurnitureAvailable: PropTypes.bool,
    PatioFurnitureAvailable: PropTypes.bool,
    MattressFurnitureAvailable: PropTypes.bool,
    SpecialClosings: PropTypes.object,
    SpecialClosingsBanner: PropTypes.object,
    State: PropTypes.string,
    StoreImage: PropTypes.object,
    StoreImageMobile: PropTypes.object,
    StoreName: PropTypes.string,
    StoreNumber: PropTypes.number,
    StoreType: PropTypes.string,
    SEO: PropTypes.object,
  }),
})

Store.propTypes = {
  data: storeShape,
}

StoreWrapper.propTypes = {
  data: storeShape,
}

export default StoreWrapper

export const query = graphql`
  query($storeID: String) {
    strapiStore(id: { eq: $storeID }) {
      id
      Banner {
        Banner {
          ...StrapiPageBannerFragment
        }
      }
      StoreNumber
      StoreName
      StoreType
      Address1
      Address2
      City
      State
      Zip
      StoreImage {
        url
        hash
      }
      StoreImageMobile {
        url
        hash
      }
      Location {
        latLng {
          lat
          lng
        }
      }
      PhoneNumber
      StoreHours {
        mondayClosed
        mondayOpen
        tuesdayClosed
        tuesdayOpen
        wednesdayClosed
        wednesdayOpen
        thursdayClosed
        thursdayOpen
        fridayClosed
        fridayOpen
        saturdayClosed
        saturdayOpen
        sundayClosed
        sundayOpen
      }
      Description {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      Directions {
        data {
          childMarkdownRemark {
            html
          }
        }
      }
      NearbyNeighborhoods {
        Name
      }
      InStoreAppointments
      SeniorAppointments
      RegularAppointments
      Payonline
      FAQs {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      SpecialClosings {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      SpecialClosingsBanner {
        Banner {
          ...StrapiPageBannerFragment
        }
      }
      SEO {
        SEO {
          id
          PageTitle
          PageHeading
          Canonical
          MetaRobots
          MetaDescription
          LD_Schema {
            address {
              _type
              addressCountry
              addressLocality
              addressRegion
              postalCode
              streetAddress
            }
            contactPoint {
              _type
              contactType
              telephone
            }
            geo {
              _type
              latitude
              longitude
            }
            mainEntity {
              _type
              acceptedAnswer {
                _type
                text
              }
              name
            }
            potentialAction {
              _type
              query_input
              target
            }
            _type
            _context
            areaServed
            description
            hasMap
            image
            logo
            name
            paymentAccepted
            openingHours
            priceRange
          }
        }
      }
      NewTemplateVersion
      HomeFurnitureAvailable
      KidsFurnitureAvailable
      PatioFurnitureAvailable
      MattressFurnitureAvailable
      ImageGallery {
        alternativeText
        url
      }
      Accordion {
        Accordion {
          Heading
          Hidden
          id
          Markdown {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  }
`
